import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="circle-icon"
    className="size-5"
    strokeWidth="1.5"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    {...props}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
))

export default icon
